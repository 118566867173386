import React from "react";
import { Helmet } from "react-helmet";

import Footer from "../components/Footer";
import Header from "../components/Header";
import HiringToolDemoVideo from "../assets/hiring-tool-demo.mp4";
import HiringToolDemoThumbnail from "../assets/hiring-tool-demo-thumbnail.png";
import { Box, Button } from "@logrock/pebbles";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { up } from "styled-breakpoints";

const HiringToolDemo = () => {
  const isDesktop = useBreakpoint(up("desktop"));

  return (
    <>
      <Helmet>
        <title>{"LogRock - The only FREE compliance software"}</title>
      </Helmet>
      <Header noPaddingDesktop />
      <Box
        style={{ width: "100%" }}
        display="grid"
        gridColumns="repeat(12,1fr)"
        spaceAfter="lg"
        align="stretch"
      >
        <Box
          gridArea={isDesktop ? "1/4/2/span 6" : "1/2/2/span 10"}
          spaceAfter="lg"
          padding="xbig"
        >
          <video
            controls
            poster={HiringToolDemoThumbnail}
            style={{ width: `100%` }}
          >
            <source src={HiringToolDemoVideo} type="video/mp4" />
          </video>
        </Box>
        <Box gridArea="2/4/3/span 6">
          <Button
            variant="primary"
            buttonSize="large"
            spaceAfter="huge"
            onClick={() =>
              (window.location.href = "https://app.logrock.com/onboarding")
            }
          >
            {"Start Hiring Drivers"}
          </Button>
        </Box>
      </Box>
      <Box
        display="flex"
        align="stretch"
        style={{ position: "fixed", bottom: "0px" }}
      >
        <Footer />
      </Box>
    </>
  );
};

export default HiringToolDemo;
